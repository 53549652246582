import React, { useState } from 'react'
import Layout from '../components/structure/layout.js'
import downloadFile from '../../static/Tannity_franquiciados.pdf' 

export default function Dossier() {
    {/* Facebook and Google píxels */}
    const [flagFbPixel, setflagFbPixel] = useState(false)
    const [flagGooglePixel, setflagGooglePixel] = useState(false)

    if (typeof window !== "undefined") {
        if (window.fbq != null && flagFbPixel === false) {
            window.fbq('track', 'CompleteRegistration', {content_name: 'lead', status: true});
            setflagFbPixel(true)
        }

        if (window.gtag != null && flagGooglePixel === false) {
            window.gtag('event', 'generate_lead', {})
            setflagGooglePixel(true)
        }

        if (window.linktrk != null) {
            window.lintrk('track', { conversion_id: 6626818 });
        }
    }

    return (
        <>
            <Layout>
                <section className="container relative px-4 py-8 pt-4 mx-auto mb-0 text-center text-white bg-yellow-600 sm:px-6 lg:px-8 lg:mb-16">
                    <h2 className="pb-8 text-4xl">Agenda una reunión con nuestro equipo de franquicias</h2>
                    <button>
                        <a 
                            href="https://calendly.com/franquicias-tannity/info-franquicia-tannity"
                            target="_blank"
                            className="items-center px-4 py-2 text-2xl text-white bg-yellow-500 border border-transparent rounded-md shadow-sm md:text-4xl hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                                Agendar reunión
                        </a>
                    </button>
                </section>
                <section className="container relative px-4 pb-8 mx-auto mb-8 text-center bg-yellow-600 md:py-16 sm:px-6 lg:px-8 lg:mb-16">
                    <img
                    className="object-cover w-full h-full pb-4 md:h-96 md:object-contain"
                    src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/tannity_sevilla.jpg?v=1655207368"
                    alt="Tannity Alicante, la primera franquicia."
                    />
                    <h1 className="pb-8 text-4xl">¡Ya estás un paso más cerca de formar parte de la familia Tannity!</h1>
                    <h2 className="pb-8 text-xl underline">Hazte con nuestro dossier</h2>
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-2">
                        <div className="md:col-span-1">
                            <a 
                                href={downloadFile} 
                                download
                                className="items-center px-4 py-2 text-2xl text-white bg-yellow-500 border border-transparent rounded-md shadow-sm md:text-4xl hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                                    Descargar
                            </a>
                        </div>
                        <div className="md:col-span-1">
                            <a 
                                href={downloadFile} 
                                target="_blank"
                                className="items-center px-4 py-2 text-2xl text-white bg-gray-500 border border-transparent rounded-md shadow-sm md:text-4xl hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                    Visualizar
                            </a>
                        </div>
                    </div>
                </section>

                <section className="container relative hidden px-4 py-16 mx-auto mb-8 text-center md:block sm:px-6 lg:px-8 lg:mb-16">
                    <img
                    className="object-cover w-full h-full md:h-96 md:object-contain"
                    src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/product2.jpg?v=1638782934"
                    alt="Tannity model"
                    />
                </section>
            </Layout>
        </>
    )
} 